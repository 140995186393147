import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeJournalApi = createApi({
    reducerPath: "financeJournalApi",
    tagTypes: ["finance-journals"],
    baseQuery: axiosFetchBase("/finance-journals"),
    endpoints: (builder) => ({
        getFinanceJournals: builder.query({
            query: (args) => ({
                url: "/",
                method: "GET",
                initialData: args.initialData,
                query: {
                    search: args.search,
                    page: args.page,
                    pageSize: args.pageSize,
                    startDate: args.startDate,
                    endDate: args.endDate,
                },
            }),
            providesTags: (_, __, arg) => [
                {
                    type: "finance-journals",
                    id: "list",
                    search: arg.search,
                    page: arg.page,
                    pageSize: arg.pageSize,
                    startDate: arg.startDate,
                    endDate: arg.endDate,
                },
            ],
        }),
        getFinanceAccountById: builder.query({
            query: (data) => ({
                url: `/${data.id}`,
                method: "GET",
            }),
            providesTags: (_, __, arg) => [
                { type: "finance-journals", id: arg.id },
            ],
        }),
        postFinanceJournal: builder.mutation({
            query: (data) => ({
                url: `/post/${data.journalId}`,
                method: "POST",
            }),
            invalidatesTags: ["finance-journals"],
        }),
    }),
});

export const {
    useGetFinanceJournalsQuery,
    useGetFinanceAccountByIdQuery,
    usePostFinanceJournalMutation,
} = financeJournalApi;
