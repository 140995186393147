import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosFetchBase } from "@/services/http.js";

export const financeAccountApi = createApi({
	reducerPath: "financeAccountApi",
	tagTypes: ["finance-accounts"],
	baseQuery: axiosFetchBase("/finance-accounts"),
	endpoints: (builder) => ({
		getFinanceAccounts: builder.query({
			query: (args) => ({
				url: "/",
				method: "GET",
				initialData: args.initialData,
				query: {
					search: args.search,
                    expandedGroups: args.expandedGroups,
				},
			}),
			providesTags: [{ type: "finance-accounts", id: "list" }],
		}),
		getFinanceAccountById: builder.query({
			query: (data) => ({
				url: `/${data.id}`,
				method: "GET",
			}),
			providesTags: (_, __, arg) => [
				{ type: "finance-accounts", id: arg.id },
			],
		}),
		createFinanceAccount: builder.mutation({
			query: (data) => ({
				url: "/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: [{ type: "finance-accounts", id: "list" }],
		}),
		updateFinanceAccount: builder.mutation({
			query: (data) => ({
				url: `/${data.id}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_, __, arg) => [
				{ type: "finance-accounts", id: "list" },
				{ type: "finance-accounts", id: arg.id },
			],
		}),
		deleteFinanceAccount: builder.mutation({
			query: (data) => ({
				url: `/${data.id}`,
				method: "DELETE",
			}),
			invalidatesTags: (_, __, arg) => [
				{ type: "finance-accounts", id: "list" },
				{ type: "finance-accounts", id: arg.id },
			],
		}),
	}),
});

export const {
	useGetFinanceAccountsQuery,
	useGetFinanceAccountByIdQuery,
	useCreateFinanceAccountMutation,
	useUpdateFinanceAccountMutation,
	useDeleteFinanceAccountMutation,
} = financeAccountApi;
